import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

import FullWidthImage from "../components/FullWidthImage";
import { getImage } from "gatsby-plugin-image";

// eslint-disable-next-line
export const CoinPageTemplate = ({
  title,
  content,
  contentComponent,
  lang,
  image,

  coins,
  countries,
  totalcount,
  regions,
}) => {
  const PageContent = contentComponent || Content;
  const heroImage = getImage(image) || image;

  const menu = regions.map((region, count) => {
    return (
      <tr key={region.node.id}>
        <td>
          <a href={"#region" + count}>{region.node.region}</a>
        </td>
      </tr>
    );
  });

  const ret = regions.map((region, count) => {
    const country_by_region = countries.filter((country) => {
      return country.node.region === region.node.region;
    });
    const country_entry = country_by_region.map((country) => {
      const coin_by_country = coins.filter((coin) => {
        return coin.node.Country === country.node.country;
      });
      const coin_entry = coin_by_country.map((coin) => {
        return (
          <tr key={coin.node.id}>
            <td>
              <img src={coin.node.Image1} />
            </td>
            <td>
              <img src={coin.node.Image2} />
            </td>
            <td>
              {coin.node.ValueText}
              <br />
              {coin.node.Desc}
            </td>
          </tr>
        );
      });

      return (
        <div key={country.node.id}>
          <h5 className="is-size-5 has-text-weight-bold has-text-primary">
            {country.node.country}
          </h5>
          <div className="box">
            <HTMLContent content={country.node.description} className="" />
            <table className="table is-bordered is-striped">
              <tbody>{coin_entry}</tbody>
            </table>
            <a href="#region0">上へ</a>
          </div>
        </div>
      );
    });
    return (
      <div key={region.node.id}>
        <h4 className="is-size-4 has-text-primary">
          <a name={"region" + count}></a>
          {region.node.region}
        </h4>
        <div className="box">{country_entry}</div>
      </div>
    );
  });

  return (
    <div>
      <Navbar lang={lang} />
      <FullWidthImage img={heroImage} title={title} />
      <section className="section section--gradient">
        <Helmet title={`${title} | 飯山研究室`} />
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section">
                <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                  {title}
                </h2>
                <PageContent className="content" content={content} />
                <h3 className="title is-size-4 has-text-weight-bold is-bold-light">
                  収集済みメダル一覧
                </h3>
                <table className="table">
                  <thead>
                    <tr>
                      <th>地域({totalcount}ヵ国)</th>
                    </tr>
                  </thead>
                  <tbody>{menu}</tbody>
                </table>
                {ret}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer lang={lang} />
    </div>
  );
};

CoinPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const CoinPage = ({ data }) => {
  const {
    markdownRemark: post,
    allCoinXlsxCoin: coins,
    allCoinXlsxCountry: countries,
    allCoinXlsxRegion: regions,
  } = data;

  return (
    <Layout>
      <CoinPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        lang={post.frontmatter.lang}
        image={post.frontmatter.image}
        content={post.html}
        coins={coins.edges}
        countries={countries.edges}
        totalcount={countries.totalCount}
        regions={regions.edges}
      />
    </Layout>
  );
};

CoinPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CoinPage;

export const coinPageQuery = graphql`
  query coinPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        lang
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
    allCoinXlsxRegion(sort: { fields: No }) {
      edges {
        node {
          region
          id
        }
      }
    }
    allCoinXlsxCountry(sort: { fields: No }) {
      edges {
        node {
          country
          region
          description
          id
        }
      }
      totalCount
    }
    allCoinXlsxCoin(sort: { fields: Value }) {
      edges {
        node {
          Country
          ValueText
          Value
          Desc
          Image1
          Image2
          id
        }
      }
    }
  }
`;
